/* eslint-disable react/prop-types */
import React from 'react'
import { Link } from 'gatsby'

const TrainingHeader = ({mainTitle, text, color}) => {
    return (
        <div className={`${color === 'blue' ?'bg-lightBlue text-mainColor' : ' bg-lightBlue2  text-white '}`}>
            <header className={`py-10 ${color === 'blue' ?'bg-lightBlue text-mainColor' : ' bg-lightBlue2  text-white '} grid sm:grid-cols-3 max-w-screen-xl w-4/5 md:w-full m-auto items-center justify-center`}>
                <div className="text-left ml-5 col-span-2">
                    <div className="text-xl sm:text-3xl items-center mb-2">{mainTitle}</div>
                    <p className="md:text-xl items-center">{text}</p>
                </div>
                <div className="flex justify-center items-center">
                    <Link to={mainTitle=== 'Coaching/Consultancy Office'? '/coachingForm' : '/trainingForm'} className=" text-center bg-yellowColor font-semibold px-4 py-2 w-full m-3 text-mainColor hover:text-lightBlue2 rounded-md no-underline p-2 ">Ask for Details !</Link>
                </div>     
            </header>
        </div>
      
    )
}
export default TrainingHeader